<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="protocolForm"
               label-width="140px"
               :rules="rules" ref="protocolForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="protocolForm.xingming"  placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="身份证/通行证" prop="IDNo">
          <el-input v-model="protocolForm.IDNo" placeholder="请填写身份证/通行证" disabled></el-input>
        </el-form-item>
        <el-form-item label="住址" prop="ConsultantAddress">
          <el-input v-model="protocolForm.ConsultantAddress " placeholder="请填写住址"></el-input>
        </el-form-item>
        <el-form-item label="签署协议日期" prop="ConsultantJoinDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.ConsultantJoinDate"
                          type="date"
                          placeholder="请选择签署协议日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="合同到期日期" prop="ConsultantResignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.ConsultantResignDate"
                          type="date"
                          placeholder="请选择合同到期日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="解除协议日期" prop="ConsultantTerminationDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.ConsultantTerminationDate"
                          type="date"
                          placeholder="请选择解除协议日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工资发月份" prop="ResignSalaryReleaseMonth">

          <el-input type="number" v-model="protocolForm.ResignSalaryReleaseMonth" placeholder="请填写离职工资发放月份">
            <template slot="append">月份</template>
          </el-input>
        </el-form-item>
        <el-form-item label="签订日期" prop="ConsultantResignSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="protocolForm.ConsultantResignSignDate"
                          type="date"
                          placeholder="请选择签订日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('protocolForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>


    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载兼职终止通知
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传兼职终止通知反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载兼职终止通知反馈附件
        </el-link>
      </div>
    </div>


  </div>
</template>

<script>

import rules from "@/utils/rules";
import {QAHOST, upFile} from "@/config";
import {
  departStaffPartTimeAgreement,
  departStaffPartTimeAgreementReply,
  departStaffPartTimeAgreementShow
} from "@/api/resignation";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {downLoadOfferWord} from "@/api/entry";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      protocolForm: {
        xingming: '',
        IDNo: '',
        ConsultantAddress: '',
        ConsultantJoinDate: '',
        ConsultantResignDate: '',
        ConsultantTerminationDate: '',
        ResignSalaryReleaseMonth: '',
        ConsultantResignSignDate: '',
      },
      rules: {
        xingming: rules.mustInput,
        IDNo: rules.mustInput,
        ConsultantAddress: rules.mustInput,
        ConsultantJoinDate: rules.mustDate,
        ConsultantResignDate: rules.mustDate,
        ConsultantTerminationDate: rules.mustDate,
        ResignSalaryReleaseMonth: rules.mustInput,
        ConsultantResignSignDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "兼职终止通知", true);
    this.id = Number(this.$route.params.id)
    this.departStaffPartTimeAgreementShow()
  },
  components: {},
  methods: {
    async departStaffPartTimeAgreementShow() {
      const res = await departStaffPartTimeAgreementShow(this.id)
      if (res.data) {
        this.protocolForm = {
          xingming: res.data.xingming,
          IDNo: res.data.IDNo,
          ConsultantAddress: res.data.ConsultantAddress,
          ConsultantJoinDate: res.data.ConsultantJoinDate ? new Date(res.data.ConsultantJoinDate) : '',
          ConsultantResignDate: res.data.ConsultantResignDate ? new Date(res.data.ConsultantResignDate) : '',
          ConsultantTerminationDate: res.data.ConsultantTerminationDate ? new Date(res.data.ConsultantTerminationDate) : '',
          ResignSalaryReleaseMonth: res.data.ResignSalaryReleaseMonth,
          ConsultantResignSignDate: res.data.ConsultantResignSignDate ? new Date(res.data.ConsultantResignSignDate) : '',
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        this.protocolForm.xingming = info.data.part_time_staff.xingming
        this.protocolForm.IDNo = info.data.part_time_staff.shenfenzhenghao
        this.protocolForm.ConsultantAddress = info.data.part_time_staff.xianjudizhi
        this.protocolForm.ConsultantJoinDate = new Date(info.data.part_time_staff.ConsultantJoinDate)
        this.protocolForm.ConsultantResignDate = new Date(info.data.part_time_staff.ConsultantResignDate)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffPartTimeAgreement(
        this.id,
        this.protocolForm.xingming,
        this.protocolForm.IDNo,
        this.protocolForm.ConsultantAddress,
        zhDate(new Date(this.protocolForm.ConsultantJoinDate)),
        zhDate(new Date(this.protocolForm.ConsultantResignDate)),
        zhDate(new Date(this.protocolForm.ConsultantTerminationDate)),
        this.protocolForm.ResignSalaryReleaseMonth,
        zhDate(new Date(this.protocolForm.ConsultantResignSignDate)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffPartTimeAgreementShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.protocolForm.xingming + '兼职终止通知.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffPartTimeAgreementReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffPartTimeAgreementShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 280px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
